<template>
  <aside>
    <header>
      <h2>Top-up Receipt</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main ref="receipt-body">
      <template v-if="payment_intent !== null">
        <h1>Your HiThrive rewards account has been funded</h1>
        <p>We've charged your credit card ending in <strong>x{{charge.payment_method_details.card.last4}}</strong> and your points have been credited. Please save this receipt for your records.</p>

        <ul class="transaction-details">
          <li>
            <label>Transaction ID</label>
            <span>
              {{topup.transaction.pi_id}}
            </span>
          </li>

          <li>
            <label>Transaction date</label>
            <span>
              {{$formatDate(topup.created_at, $DateTime.DATETIME_FULL)}}
            </span>
          </li>

          <li>
            <label>Points purchased</label>
            <span>
              {{$formatPoints(topup.points)}}
            </span>
          </li>

          <li>
            <label>Point cost</label>
            <span>
              {{$formatCurrency(payment_intent.metadata.topup/100)}}
            </span>
          </li>

          <li>
            <label>Platform fee</label>
            <span>
              {{$formatCurrency(payment_intent.metadata.topup_fee/100)}}
            </span>
          </li>

          <li>
            <label>Total charged</label>
            <span>
              {{$formatCurrency(payment_intent.amount/100)}}
            </span>
          </li>

          <li>
            <label>Payment method</label>
            <span>
              <div class="payment-method">
                <strong>{{charge.payment_method_details.card.brand.toUpperCase()}}</strong>
                <div>x{{charge.payment_method_details.card.last4}} <small>({{charge.payment_method_details.card.exp_month}}/{{charge.payment_method_details.card.exp_year}})</small></div>
              </div>
            </span>
          </li>
        </ul>
      </template>
      <loading-indicator small v-else />
    </main>

    <footer>
      <button :class="['btn', 'btn-light', 'btn-sm', {'loading': loading}]" :disabled="loading" @click="savePDF">Save PDF</button>
    </footer>
  </aside>
</template>

<script>
import html2pdf from 'jspdf-html2canvas'

export default {
  props: ['data'],
  computed: {
    charge() {
      return this.payment_intent.charges.data[0];
    }
  },
  data() {
    return {
      payment_intent: null,
      topup: null,
      loading: false
    }
  },
  mounted() {
    this.topup = _.cloneDeep(this.data.topup);

    this.populatePaymentIntent();
  },
  methods: {
    close() {
      this.$emit('done');
    },
    async populatePaymentIntent() {
      const resp = await this.$api.Billing.get_payment_intent(this.topup.transaction.pi_id)

      this.payment_intent = resp;
    },
    async savePDF() {
      this.loading = true;

      const pdf = await html2pdf(this.$refs['receipt-body'], {
        imageType: 'image/png',
        output: `HiThrive-Topup-Receipt.pdf`,
        jsPDF: {
          unit: 'px',
          format: [
            600,
            1000
          ]
        },
        margin: {
          top: 30,
          right: 30,
          bottom: 30,
          left: 30
        }
      })

      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
.transaction-details {
  padding: 0;
  margin: 25px 0 0;
  display: block;

  > li {
    display: flex;
    padding: 5px 0;

    > label {
      display: block;
      flex: 0 0 30%;
      color: $muted-text;
      margin: 0;
    }

    > span {
      display: block;
      flex: 0 0 auto;
      margin: 0 0 0 auto;
    }
  }
}

.payment-method {
  display: flex;
  align-items: center;

  > strong {
    margin: 0 5px 0 0;
  }

  > div {
    padding: 0 0 0;
    line-height: 12px;
    font-size: 12px;
    > small {
      color: $muted-text;
    }
  }
}

h1 {
  font-weight: 700;
  font-size: 22px;

  + p {
    svg {
      display: inline-block;
      height: 20px;
      border-radius: 3px;
    }
  }
}
</style>